import { platform } from "@constants/config";

export const homepageCarousalArrowIcon = require('./Images/homepageCarousalArrow.svg');
export const DeleteIcon = require("./Icons/delete-icon.svg");
export const ChatIcon = require('./Icons/chat.svg');
export const NewChat = require('./Icons/NewChat.svg');
export const PlaceholderImage = require("./Images/PlaceholderGrey.jpg");
export const SendMessage = require('./Icons/SendMessage.svg');
export const ModalClose = require("./Icons/ModalClose.svg");
//@ts-ignore
export const SignIn = platform.WITP ? require('./Images/witpLogin.png') : require('./Images/SignIn.png');
export const ShowPassword = require('./Icons/ShowPassword.svg');
export const HidePassword = require('./Icons/HidePassword.svg');
export const BackBigger = require('./Icons/BackBigger.svg');
export const InactiveLock = require("./Icons/InactiveLock.svg");
export const ActiveLock = require("./Icons/ActiveLock.svg");
export const Password = require("./Images/Password.png");
export const Enroll = require("./Images/Enroll.png");
//@ts-ignore
export const HoldingTablet = platform.WITP ? require("./Images/holdingTabletWitp.png") : require("./Images/holdingTablet.png");
export const GroupPic = require("./Images/Vector.png");
export const WelcomePic = require("./Images/WelcomeImage.png");
export const LoadingGif = require('./Images/loading.gif');
export const EmptyResult = require('./Images/EmptySearchResult.png');
export const EmptyResultGray = require('./Images/EmptySearchGray.png');
export const OpenLibrary = require('./Images/open-lib.png');
export const Cookies = require('./Images/cookies.png');
export const InterviewPrep_EN = require('./Images/interview(EN).png');
export const InterviewPrep_ES = require('./Images/interview(ES).png');
export const InterviewPrep_PT = require('./Images/interview(PT).png');
export const ToolkitScreenShot_EN = require('./Images/toolkitSS(EN).png');
export const ToolkitScreenShot_ES = require('./Images/toolkitSS(ES).png');
export const ToolkitScreenShot_PT = require('./Images/toolkitSS(PT).png');
export const FacToolkitScreenShot_EN = require('./Images/facToolkitSS(EN).png');
export const FacToolkitScreenShot_ES = require('./Images/facToolkitSS(ES).png');
export const FacToolkitScreenShot_PT = require('./Images/facToolkitSS(PT).png');
export const DownloadOutlined = require('./Icons/DownloadOutlined.svg');
export const forumTopicIcon = require('./Icons/forumTopic.svg');
export const WhatsappIcon = require("./Icons/WhatsappIcon.svg");
export const courseContentIcon = require("./Icons/course-content.svg");
export const setupAppointments = require('./Images/setUpAppointments.svg');
export const pitchTrainerBackIcon = require("./Icons/pitch-trainer-back-arrow.svg");
export const pitchTrainerNotesIcon = require("./Icons/pitch-notes.svg");
export const recordPitchHeaderIcon = require("./Icons/record-pitch-header-icon.svg");
export const recordPitchplaceholderIcon = require("./Icons/record-pitch-placeholder.svg");
export const recordButtonIcon = require("./Icons/record-pitch-button.svg");
export const restartDisabledIcon = require("./Icons/restart-disabled.svg");
export const restartEnabled = require("./Icons/restart-enabled.svg");
export const reviewButtonIcon = require("./Icons/review-button.svg");
export const resumeButtonIcon = require("./Icons/resume-button.svg");
export const pitchToastLoader = require("./Icons/pitch-toast-loader.svg");
export const notesEdit = require("./Icons/notes-edit.svg");
export const viewPitchIcon = require("./Icons/view-pitch-icon.svg");
export const feedbackShareIcon = require("./Icons/feedback-share-icon.svg");
export const overAllRatingIcon = require("./Icons/over-all-rating-icon.svg");
export const ratingOne = require("./Icons/rating-one.svg");
export const ratingTwo = require("./Icons/rating-two.svg");
export const ratingThree = require("./Icons/rating-three.svg");
export const ratingFour = require("./Icons/rating-four.svg");
export const ratingFive = require("./Icons/rating-five.svg");
export const notificationBell = require("./Icons/notification-bell.svg");
export const pitchVideoPlaceholder = require("./Icons/pitch-video-placeholder.svg");
export const noPitchesSaved = require("./Icons/no-pitches-saved.svg");
export const shareButtonCopy = require("./Icons/share-button-copy.svg");
export const pitchTrainerBackButton = require("./Icons/pitch-trainer-back-button.svg");
export const keyInfoTooltip = require("./Icons/picth-trainer-key-info-tooltip.svg");
export const keyInfo = require("./Icons/picth-trainer-key-info.svg");
export const pitchTrainerCloseButton = require("./Icons/pitch-trainer-close-button.svg");
export const InterviewCoachBackIcon = require("./Icons/interview-coach-back-button.svg");
export const EmojiEmptyStateIcon = require("./Icons/question-emoji-empty-state.svg");
export const journeyLocked = require("./Icons/journey-locked.svg");
export const journeyUnlocked = require("./Icons/journey-unlocked.svg");
export const journeyCompleted = require("./Icons/journey-completed.svg");
export const interviewCoachPlayButton = require("./Icons/interview-coach-play-button.svg");
export const interviewCoachPlaceholderImage = require("./Icons/interview-coach-placeholder-image.svg");
export const interviewCoachDisabledButton = require("./Icons/interview-coach-disabled-button.svg");
export const interviewCoachStopButton = require("./Icons/interview-coach-stop-button.svg");
export const interviewCoachStartButton = require("./Icons/interview-coach-start-button.svg");
export const interviewCoachRestartButton = require("./Icons/interview-coach-restart-button.svg");
export const interviewCoachResumeButton = require("./Icons/interview-coach-resume-button.svg");
export const hideVideoCamera = require("./Icons/hide-video-camera.svg");
export const interviewCoachSendIcon = require("./Icons/interview-coach-send-icon.svg");
export const interviewCoachInfoIcon = require("./Icons/interview-coach-info-icon.svg");
export const interviewCoachGenaiIcon = require("./Icons/interview-coach-genai-icon.svg");
export const learnMoreDescriptionIcon = require("./Icons/learn-more-description-icon.svg");
export const roundSuccess = require("./Icons/round-success.svg");
export const roundFailed = require("./Icons/round-failed.svg");
export const questionPositive = require("./Icons/question-positive.svg");
export const questionNegative = require("./Icons/question-negative.svg");
export const moreDetails = require("./Icons/more-details.svg");
export const deleteJobRole = require("./Icons/delete-job-role.svg");
export const interviewCoachHistory = require("./Icons/intervew-coach-history.svg");
export const listenResponsePlay = require("./Icons/listen-response-play.svg");
export const listenResponseResume = require("./Icons/listen-response-resume.svg");
export const questionCompletedState = require("./Icons/question-completed-state.svg");
export const videoOff = require("./Icons/video-off-icon.svg");
export const preparationJourneyBanner = require("./Icons/preparation-journey-banner.svg");
export const viewMoreIcon = require("./Icons/view-more.svg");
export const readTranscriptIcon = require("./Icons/read-transcript-icon.svg");
export const propertyPositive = require("./Icons/property-positive.svg");
export const propertyNegative = require("./Icons/property-negative.svg");
export const mexicoProfile = require("./Icons/mexico-profile.svg");
export const brazilProfile = require("./Icons/brazil-profile.svg");
export const egyptProfile = require("./Icons/egypt-profile.svg");
export const phillipinesProfile = require("./Icons/phillipines-profile.svg");
export const indonesiaProfile = require("./Icons/indonesia-profile.svg");
export const OverallScoreDescription = require("./Images/Overall_Sore_Description.svg")
export const Lock = require("./Icons/Lock.svg")












